import React from "react";
import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import Conditions from "../../components/Conditions";
import ServicesHeader from "../../components/ServicesHeader";
import DiabetesInfo from "../../components/services/DiabetesInfo";

const Diabetes = () => {
  return (
    <Layout>
      <PageHeader text="Diabetes" />
      <ServicesHeader>
        Diabetes is a very common problem requiring careful management and
        expert medical care to prevent life-changing complications. At Sunstate
        Medical Associates in Lake Mary, Florida, the board-certified internists
        specialize in diagnosing and treating diabetes. Find out how working
        with them can improve your quality of life or make an appointment for a
        diabetic screening test. Call Sunstate Medical Associates today or book
        an appointment using the convenient online form.
      </ServicesHeader>
      <DiabetesInfo />
      <Conditions />
    </Layout>
  );
};

export default Diabetes;
