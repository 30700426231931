import React from "react";
import RequestButton from "../RequestButton";

const DiabetesInfo = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center bg-white">
      <div className="w-4/5 lg:w-1/2 flex flex-col justify-start items-start py-12">
        <p className="text-3xl font-thin text-gray-800 mb-6">Diabetes Q & A</p>
        <p className="text-xl text-gray-800 mb-2">What is diabetes?</p>
        <p className="text-md text-gray-600 mb-3">
          Diabetes is a condition that develops when you have problems with the
          hormone insulin. You need insulin to regulate the levels of glucose
          (sugar) in your blood. There are three types of diabetes:
        </p>

        <p className="text-lg text-gray-700 mb-2">Type 1</p>
        <p className="text-md text-gray-600 mb-3">
          Type 1 diabetes develops in children and young people because their
          pancreas can’t make insulin at all. This is an autoimmune problem,
          where your body’s defense system mistakenly attacks your body’s
          healthy cells. With Type 1 diabetes, it attacks the cells in the
          pancreas that produce insulin.
        </p>

        <p className="text-lg text-gray-700 mb-2">Type 2</p>
        <p className="text-md text-gray-600 mb-3">
          Type 2 diabetes develops if your body can’t produce enough insulin, or
          it becomes resistant to the insulin you’re making. It usually affects
          adults and is brought on by being overweight in many cases. However,
          as obesity rates are on the rise in children, so too are the rates of
          Type 2 diabetes.
        </p>

        <p className="text-lg text-gray-700 mb-2">Gestational diabetes</p>
        <p className="text-md text-gray-600 mb-12">
          Gestational diabetes develops during pregnancy, raising your blood
          sugar levels and causing rapid fetal growth. As soon as the baby is
          born your blood glucose starts to normalize, but having gestational
          diabetes does make you more likely to get Type 2 diabetes later on in
          life.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          What complications can diabetes cause?
        </p>
        <p className="text-md text-gray-600 mb-2">
          Diabetes can lead to some of the most serious health problems
          affecting people across the United States, including:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>Heart disease</li>
          <li>Stroke</li>
          <li>Kidney disease</li>
          <li>High blood pressure</li>
          <li>Glaucoma</li>
          <li>Cataracts</li>
          <li>Diabetic neuropathy</li>
        </ul>
        <p className="text-md text-gray-600 mb-12">
          Diabetic peripheral neuropathy affects your nerves, particularly in
          your feet. It can cause numbness, which means you don’t feel anything
          when you hurt your foot. Ulcers and slow healing wounds can develop,
          increasing your risk of infection. In some cases, patients can lose
          their feet.
        </p>
        <p className="text-xl text-gray-800 mb-2">How is diabetes treated?</p>
        <p className="text-md text-gray-600 mb-3">
          If you have Type 1 diabetes, you need to inject yourself with insulin
          to keep your blood sugar under control. You’ll need to do this for the
          rest of your life because there’s no cure. The Sunstate Medical
          Associates team works with you to help you manage your diabetes and
          stay healthy.
        </p>
        <p className="text-md text-gray-600 mb-2">
          One of the main approaches to treating Type 2 diabetes is to make
          changes in your habits and lifestyle, reversing the causes of your
          condition. Changes you need to make include:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>Losing weight</li>
          <li>Quitting smoking</li>
          <li>Cutting down on alcohol</li>
          <li>Getting more exercise</li>
          <li>Eating a healthy diet</li>
        </ul>
        <p className="text-md text-gray-600 mb-3">
          Losing weight and eating a healthy diet can make a significant
          difference in your blood sugar. If you make these changes in the early
          stages, you can even reverse your Type 2 diabetes with these measures.
        </p>
        <p className="text-md text-gray-600 mb-3">
          You might also need to take medication, like metformin, to help you
          get your diabetes under control, and in some cases, insulin
          injections.
        </p>
        <p className="text-md text-gray-600 mb-3">
          The Sunstate Medical Associates team specializes in helping patients
          manage conditions like diabetes. They also offer in-office screening
          tests so you can find out whether you have diabetes before you
          experience any symptoms.
        </p>
        <p className="text-md text-gray-600 mb-10">
          Call Sunstate Medical Associates today to arrange your diabetic
          screening or book an appointment online.
        </p>
        <RequestButton />
      </div>
    </div>
  );
};

export default DiabetesInfo;
